import React, { Component } from 'react';
import { Switch, Route, withRouter, Router } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PrivateRoute from './utils/PrivateRoute';
import { check } from './utils/CheckRole';
import { connect } from 'react-redux';
import history from './utils/history';
import { setModuleStatus } from './actions/module';
import SingleLayout from './components/layout/SingleLayout';

import RoadRepairPage from './pages/RoadRepairPage';
import RoadList from './pages/RoadList/RoadList';
import HomePage from './pages/HomePage';
import CrossesList from './pages/CrossesList';
import UsersList from './pages/User/UsersList';
import RolesPage from './pages/RolesPage';
import CatalogsPage from './pages/CatalogsPage';
import Error from './pages/Error/Error';
import { GosServiceList } from './pages/GosService/GosServiceList';
import PassengerTransportPage from './pages/PassengerTransportPage/PassengerTransportPage';
import { UserActions } from './pages/UserActions/UserActions';

class App extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    history.listen((location) => {
      console.log(
        'componentDidMount',
        this.props.module.modules,
        location.pathname
      );
      this.props.dispatch(
        setModuleStatus(check(this.props.module.modules, location.pathname))
      );
    });
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          {/* <Route exact path="/list/add" component={RoadRepairPage} /> */}
          <Route exact path="/login" component={SingleLayout} />
          {/* <Route exact path="/list" component={RoadList} /> */}
          {/* <Route exact path="/crosses" component={CrossesList} /> */}
          {/* <Route exact path="/users/list" component={UsersList} /> */}
          {/* <Route exact path="/roles/list" component={RolesPage} /> */}
          {/* <Route exact path="/integration" component={Integration} /> */}
          {/* <Route exact path="/indicators" component={IndicatorView} /> */}
          {/* <Route exact path="/catalogs" component={CatalogsPage} /> */}
          {/* <Route exact path="/audit-discrepancy" component={Audit} /> */}
          {/* <Route exact path="/risks" component={Risks} /> */}
          {/* <Route exact path="/duplicate" component={Duplicate} /> */}
          {/* <Route exact path="/integrations" component={Integrations} /> */}
          {/* <Route exact path="/send-mail" component={SendMail} /> */}
          {/* <Route exact path="/media" component={Media} /> */}
          {/* <Route exact path="/" component={HomePage} /> */}

          <PrivateRoute exact path="/list" component={RoadList} />
          <PrivateRoute exact path="/crosses" component={CrossesList} />
          <PrivateRoute exact path="/list/add" component={RoadRepairPage} />
          <PrivateRoute exact path="/roles/list" component={RolesPage} />
          <PrivateRoute exact path="/users/list" component={UsersList} />
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/catalogs" component={CatalogsPage} />
          <PrivateRoute exact path="/gosuslugy" component={GosServiceList} />

          <PrivateRoute exact path="/passenger-transport" component={PassengerTransportPage} />

          <Route exact path="/user-actions" component={UserActions} />

          <Route exact path="/error" component={Error} />
        </Switch>
      </Router>
    );
  }
}
function mapStateToProps(state) {
  const { user, module } = state;
  return {
    user,
    module,
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps)(App)));
